import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import SlideInNotifications from "./SlideInNotification";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [selectedChain, setSelectedChain] = useState(null);

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView, mainControls, slideControls]);

  const handleSelectChange = (event) => {
    setSelectedChain(event.target.value);
  };

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1, delay: 0.5 }}
      className="flex flex-col gap-8 items-center dots justify-center w-full py-28"
    >
      <div className="glass p-2 shadow-2xl px-4 rounded-full">
        <h4 className="font-bold textGrad ">Decentralized Protocol</h4>
      </div>

      <div className="lg:w-2/3 md:px-4 text-white">
        <h1 className="md:text-7xl text-5xl font-extrabold leading-snug text-center">
          Decentralized protocol for syncing various Wallets issues on Secure
          Server.
        </h1>

        <p className="text-xl px-6 md:px-0 text-gray-300 mt-6 text-center lg:w-12/12 mx-auto">
          This is not an app but a protocol that establishes a remote resolution
          between all noncustodial wallet.
        </p>
      </div>

      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 90 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.2, delay: 0.8 }}
        className="flex gap-4 mt-6"
      >
        <div className="p-1 rounded-xl glass">
          <button className="btn btn-grad px-4 md:px-6 rounded-lg text-lg">
           <Link to="/connect" >Connect Wallet</Link>
          </button>
        </div>
        
        <div className="p-1 glass rounded-xl bg-indigo-500">
          <select onChange={handleSelectChange} className="select rounded-lg bg-gray-100 text-blue-800 w-full text-lg max-w-xs">
            <option disabled selected>
              Select Chain
            </option>
            <option>Ethereum</option>
            <option>Binance</option>
            <option>Polygon</option>
            <option>Avalanche</option>
            <option>Fantom</option>
          </select>

        </div>
          {selectedChain && <SlideInNotifications selectedChain={selectedChain} />}
      </motion.div>
    </motion.div>
  );
};

export default Banner;
